import React from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import apiv2 from "../lib/apiv2";

function ManualBillPayPalBtn({code}) {
  const navigate = useNavigate();
  async function createOrder() {
    const { data } = await apiv2.post("/manualBill/payPal/create", {code});
    return data.id;
  }

  async function onApprove(data) {
    try {
      await apiv2.post("/manualBill/payPal/capture", {
        orderID: data.orderID,
        code,
      });
      toast.loading("Procesando pago");

      setTimeout(() => {
        toast.success("Pago realizado con éxito");
        navigate("/success");
      }, 3000);
    } catch (error) {
      toast.error("Proceso de pago fallido");
    }
  }

  function onError(err) {
    console.log(err);
    toast.error("Proceso de pago fallido");
  }

  function onCancel() {
    toast.warning("Proceso de pago cancelado");
  }

  return (
    <PayPalButtons
      onCancel={onCancel}
      onError={onError}
      createOrder={createOrder}
      onApprove={onApprove}
      style={{ layout: "vertical" }}
    />
  );
}

export default ManualBillPayPalBtn;
