import React from "react";
import Nav from "../components/Nav";
import useProducts from "../hooks/useProducts";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
} from "@nextui-org/react";
import Footer from "../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowDown,
  faCloudDownload,
} from "@fortawesome/free-solid-svg-icons";

function Drivers() {
  const [products, searchProducts] = useProducts();

  return (
    <>
      <Nav />

      <main className="flex  mt-12 min-h-screen secondFont items-center flex-col mb-p-4">
        <div className="w-1/2 mb-full">
          <div className="flex gap-8 items-center justify-between mb-flex-col">
            <a
              rel="noreferrer"
              target="_blank"
              className="secondBg p-3 rounded-xl"
              href="https://www.tunerstudio.com/index.php/downloads"
            >
              Software Tunner Studio <FontAwesomeIcon icon={faCloudDownload} />
            </a>
            <Input
              placeholder="Buscar"
              onInput={(e) => searchProducts(e.target.value)}
              className="w-1/4 mb-full "
            />
          </div>
          <Table
            className="mt-5"
            isStriped
            aria-label="Example static collection table"
          >
            <TableHeader>
              <TableColumn>Producto</TableColumn>
              <TableColumn>Drivers</TableColumn>
            </TableHeader>
            <TableBody>
              {products.map((product) => {
                if (!product.manual && !product.pinout) return null;
                return (
                  <TableRow key={product.id}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>
                      {" "}
                      <a download={true} href={`./CH340G-ECU-ST.zip`}>
                        <Button className={`mainBg  mt-4`}>
                          Drivers
                          <FontAwesomeIcon icon={faCloudArrowDown} />
                        </Button>
                      </a>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Drivers;
