const countries = [
  {
    country: "Costa Rica",
    key: "CR",
  },
  {
    country: "Antigua y Barbuda",
    key: "AG",
  },

  {
    country: "Argentina",
    key: "AR",
  },
  {
    country: "Bahamas",
    key: "BS",
  },
  {
    country: "Barbados",
    key: "BB",
  },
  {
    country: "Belice",
    key: "BZ",
  },
  {
    country: "Bolivia",
    key: "BO",
  },
  {
    country: "Brazil",
    key: "BR",
  },
  {
    country: "Chile",
    key: "CL",
  },
  {
    country: "Colombia",
    key: "CO",
  },
  {
    country: "Cuba",
    key: "CU",
  },
  { country: "Dominica", key: "DM" },

  {
    country: "Ecuador",
    key: "EC",
  },
  {
    country: "El Salvador",
    key: "SV",
  },
  {
    country: "España",
    key: "ES",
  },
  {
    country: "Estados Unidos",
    key: "US",
  },
  {
    country: "Guatemala",
    key: "GT",
  },
  {
    country: "Haiti",
    key: "HT",
  },
  {
    country: "Honduras",
    key: "HN",
  },
  {
    country: "Jamaica",
    key: "JM",
  },
  {
    country: "Mexico",
    key: "MX",
  },
  {
    country: "Nicaragua",
    key: "NI",
  },
  {
    country: "Panama",
    key: "PA",
  },
  {
    country: "Paraguay",
    key: "PY",
  },
  {
    country: "Peru",
    key: "PE",
  },
  {
    country: "Puerto Rico",
    key: "PR",
  },
  {
    country: "Republica Dominicana",
    key: "DO",
  },
  {
    country: "Surinam",
    key: "SR",
  },
  {
    country: "Trinidad y Tobago",
    key: "TT",
  },
  {
    country: "Uruguay",
    key: "UY",
  },
  {
    country: "Venezuela",
    key: "VE",
  },
];

export default countries;
