import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import useDealers from "../hooks/useDealers";
import { User } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faGlobe, faMap } from "@fortawesome/free-solid-svg-icons";

function Dealers() {
  const { dealers } = useDealers();
  return (
    <>
      <Nav />
      <main className="min-h-screen flex flex-wrap gap-8  pt-12 px-8 secondFont mb-p-4">
        {dealers.map((dealer) => (
          <div
            key={dealer.id}
            className="flex flex-col gap-4 justify-between p-8 mb-full w-[300px]  diffBg shineBor h-[300px]   items-center  py-4"
          >
            <User
              name={dealer.name}
              description={`${dealer.country}, ${dealer.city}`}
              avatarProps={{
                src: `${process.env.REACT_APP_API}/files/${dealer.logo}`,
              }}
            />

            <div className="ultraSmall">
              <p className="text-gray-500">Tel {dealer.phone}</p>
              <p className="text-gray-500">{dealer.email}</p>

            </div>

            <div className="ultraSmall">
              <p className="text-gray-500">{dealer.address}</p>
            </div>

            <div className="flex gap-4">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https:wa.me/${dealer.whatsapp}`}
              >
                <FontAwesomeIcon icon={faWhatsapp} className="text-gray-500" />
              </a>

              <a target="_blank" rel="noreferrer" href={dealer.googleMaps}>
                <FontAwesomeIcon icon={faMap} className="text-gray-500" />
              </a>

              <a target="_blank" rel="noreferrer" href={dealer.website}>
                <FontAwesomeIcon icon={faGlobe} className="text-gray-500" />
              </a>
            </div>
          </div>
        ))}
      </main>
      <Footer />
    </>
  );
}

export default Dealers;
