import React from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
} from "@nextui-org/react";
import countries from "../lib/countries.js";
import { CountryContext } from "../context/Country";
import { useContext } from "react";

export default function App() {
  const { country, setCountry } = useContext(CountryContext);

  return (
    <Dropdown>
      <DropdownTrigger>
        <Button variant="bordered" className="capitalize">
          <img
            className="rounded-full w-[30px] h-[30px] "
            alt={`${country} flag`}
            src={`https://flagsapi.com/${country}/flat/64.png`}
          />
          {country}
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        className="overflow-y-scroll h-[350px]"
        aria-label="Single selection example"
        variant="flat"
      >
        {countries.map((country) => (
          <DropdownItem
            onClick={() => setCountry(country.key)}
            key={country.key}
          >
            <div className="flex items-center gap-2">
              <img
                className="rounded-full w-[30px] h-[30px] "
                alt={`${country} flag`}
                src={`https://flagsapi.com/${country.key}/flat/64.png`}
              />
              <span className="secondFont small">{country.country}</span>
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
