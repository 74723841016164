import React, { useContext } from "react";
import { CountryContext } from "../context/Country";
import { CurrencyContext } from "../context/Currency";

function Price({ price, internationalPrice, discount, className }) {
  const { currency } = useContext(CurrencyContext);
  const { country } = useContext(CountryContext);
  const international = internationalPrice ? internationalPrice : price;

  const internationalTotal = international - (international * discount || 0) / 100;
  const total = price - (price * discount || 0) / 100;

  if (country === "CR")
    return (
      <div className={`${className} flex flex-col `}>
        {discount > 0 && (
          <span className="line-through text-xs text-gray-300 font-normal">
            ₡ {price}
          </span>
        )}
        <span>₡ {total}</span>
      </div>
    );
  else
    return (

      <div className={`${className} flex flex-col`}>
        {discount > 0 && (
           <span className="line-through text-xs text-gray-300 font-normal">
           {(international / currency).toFixed(2)} USD
         </span>
        )}
        <span>{(internationalTotal / currency).toFixed(2)} USD</span>
      </div>
     
    );
}

export default Price;
