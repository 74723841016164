import { faCloudArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "@nextui-org/react";

function DownloadBtn({ file, title, color = "mainBg", className }) {

  if (!file) return null;
  return (
    <a
      download={true}
      target="_blank"
      rel="noreferrer"
      href={`${process.env.REACT_APP_API}/files/download/${file}`}
    >
      <Button className={`${color} ${className} mt-4`}>
        {title}
        <FontAwesomeIcon icon={faCloudArrowDown} />
      </Button>
    </a>
  );
}

export default DownloadBtn;
