import { createContext, useCallback, useState } from "react";
import axios from "axios";
import countries from "../lib/countries";

const CountryContext = createContext();

const CountryState = ({ children }) => {
  const [country, setCountry] = useState("CR");
  

  const isValidCountry = () => {
    if (countries.find((c) => c.key === country)) return true;
    return false;
  }

  const geolocate = useCallback(async () => {
    try {
      if (localStorage.getItem("country")) {
        setCountry(localStorage.getItem("country"));
      } else {
        const { data } = await axios.get(
          "https://ipinfo.io?token=4e9182d0470878"
        );
        localStorage.setItem("country", data.country);
        setCountry(data.country);
      }
    } catch (error) {
      setCountry("CR");
    }
  }, [setCountry]);

  return (
    <CountryContext.Provider value={{ country, setCountry, geolocate, isValidCountry }}>
      {children}
    </CountryContext.Provider>
  );
};

export { CountryContext, CountryState };
