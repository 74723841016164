import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Divider } from "@nextui-org/react";
import Price from "../components/Price";
import useBill from "../hooks/useBill";
import { useParams } from "react-router-dom";
import ManualBillPayPalBtn from "../components/ManualBillPayPalBtn";

function ManualBill() {
  const { code } = useParams();

  const { bill } = useBill(code);

  if (!bill) return null;

  return (
    <main className="h-fit min-h-screen relative whiteBg flex w-screen mb-flex-col items-center justify-around mb-p-8">
      <Link
        className="secondColor absolute top-2 left-2 secondFont p-4 rounded-xl"
        to={"/productos"}
      >
        <FontAwesomeIcon className="secondColor pr-2" icon={faArrowLeft} />
        Volver
      </Link>
      <div className="flex flex-col items-center w-1/4 mb-full  justify-center gap-4 md:px-4">
        <img className="w-[90px]  h-[90px] rounded-full" src="/st.jpg" alt="" />
        <strong className="black secondFont"></strong>
      </div>
      <div className="w-1/4 overflox-y-scroll  mb-full">
        <div>
          <div className="mb-8">
            <strong className="black secondFont">Resumen</strong>

            <p className="text-gray-600 secondFont mb-4">{bill.description}</p>
            <div className="flex justify-between">
              <p className="black small secondFont">Subtotal</p>
              <Price price={bill.total} className={"black secondFont small"} />
            </div>

            <div className="flex justify-between">
              <p className="black small secondFont">Otros cargos</p>
              <Price price={0} className={"black secondFont small"} />
            </div>

            <div className="flex justify-between">
              <p className="black small secondFont">Total</p>
              <Price price={bill.total} className={"black secondFont small"} />
            </div>
            <Divider className="my-4" />
          </div>
          <ManualBillPayPalBtn code={code} />
        </div>
      </div>
    </main>
  );
}

export default ManualBill;
