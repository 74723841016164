import { useState } from "react";
import apiv2 from "../lib/apiv2";
import { useEffect } from "react";

const useBill = (code) => {
  const [bill, setBill] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBills = async () => {
      try {
        const { data } = await apiv2.get(`/manualBill/${code}`);
        setBill(data);
      } catch (err) {}
      setLoading(false);
    };
    fetchBills();
  }, [code]);

  return { bill, loading };
};

export default useBill;
