const { useState, useEffect, useRef } = require("react");
const { default: api } = require("../lib/api");
const { useContext } = require("react");
const { CountryContext } = require("../context/Country");
const { toast } = require("sonner");

const useProducts = () => {
  const [products, setProducts] = useState([]);
  const { country } = useContext(CountryContext);
  const originalProducts = useRef([]);

  useEffect(() => {
    const getProducts = async () => {
      if (sessionStorage.getItem("products")) {
        const products = JSON.parse(sessionStorage.getItem("products"));
        setProducts(products);
        originalProducts.current = products;
      } else {
        const { data } = await api.get("/products");
        if (!data.success) {
          sessionStorage.setItem("products", JSON.stringify(data));
          setProducts(data);
          originalProducts.current = data;
        } else {
          toast.error(
            "Estamos teniendo problemas para cargar los productos, por favor intente más tarde"
          );
        }
      }
    };

    getProducts();
  }, []);

  useEffect(() => {
    let filteredData = originalProducts.current.filter(
      (product) => product.active
    );
    if (country !== "CR") {
      filteredData = filteredData.filter((product) => !product.onlyCR);
    }
    setProducts(filteredData);
  }, [country]);

  const searchProducts = (search) => {
    if (search === "") {
      setProducts(originalProducts.current);
      return;
    } else {
      const filteredData = originalProducts.current.filter((product) =>
        product.name.toLowerCase().includes(search.toLowerCase())
      );
      setProducts(filteredData);
    }
  };

  return [products, searchProducts];
};

export default useProducts;