import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Nav />
      <div className="p-6 mt-16 rounded-lg shadow-md secondFont">
        <h1 className="text-2xl  mb-4">Política de Privacidad - SpeedTech</h1>
        <p>Fecha de entrada en vigencia: 07-09-2023</p>
        <ol className="list-decimal pl-6 mt-4">
          <li className="mb-4">
            <strong>Información que Recopilamos</strong>
            <p>
              SpeedTech recopila ciertos datos personales de los usuarios para
              proporcionar sus servicios. Los datos personales recopilados
              incluyen pero no se limitan a:
            </p>
            <ul className="list-disc pl-6 light ">
              <li>
                Información de contacto (nombre, dirección, número de teléfono)
              </li>
              <li>País de residencia del usuario</li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Uso de la Información</strong>
            <p>
              Los datos personales recopilados por SpeedTech se utilizan para
              los siguientes fines:
            </p>
            <ul className="list-disc pl-6">
              <li>
                Procesar y administrar los envíos de los productos solicitados
              </li>
              <li>
                Proporcionar información sobre el estado del pedido y el
                seguimiento de envíos
              </li>
              <li>Comunicar cambios en tarifas y condiciones de servicio</li>
              <li>
                Mejorar y personalizar la experiencia del usuario en la
                plataforma
              </li>
              <li>
                Enviar comunicaciones promocionales y de marketing (solo con el
                consentimiento expreso del usuario)
              </li>
              <li>Cumplir con obligaciones legales y regulatorias</li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Protección de la Información</strong>
            <p>
              SpeedTech se compromete a proteger la privacidad y seguridad de
              los datos personales de los usuarios. Implementamos medidas de
              seguridad adecuadas para evitar el acceso no autorizado, el uso
              indebido o la divulgación de la información personal. Sin embargo,
              es importante tener en cuenta que ninguna transmisión por internet
              o sistema de almacenamiento es completamente seguro y no podemos
              garantizar la seguridad absoluta de los datos.
            </p>
          </li>
          <li className="mb-4">
            <strong>Compartir Información con Terceros</strong>
            <p>
              SpeedTech puede compartir cierta información con terceros en las
              siguientes circunstancias:
            </p>
            <ul className="list-disc pl-6">
              <li>
                Con empresas de envío y transporte para cumplir con los
                servicios solicitados
              </li>
              <li>
                Con proveedores de servicios que nos asisten en el
                funcionamiento de la plataforma
              </li>

              <li>
                Con agencias gubernamentales o autoridades legales cuando sea
                requerido por ley o para proteger nuestros derechos y propiedad
              </li>
            </ul>
          </li>
          <li className="mb-4">
            <strong>Cookies y Tecnologías de Rastreo</strong>
            <p>
              SpeedTech puede utilizar cookies y tecnologías de rastreo
              similares para mejorar la experiencia del usuario en la
              plataforma, analizar tendencias y administrar el sitio web. Los
              usuarios pueden configurar sus navegadores para rechazar cookies,
              pero esto puede afectar algunas funcionalidades del sitio. Puedes
              obtener más información sobre las cookies en nuestra{" "}
              <a href="/politica-de-cookies" className="second-txt">
                Política de Cookies
              </a>
              .
            </p>
          </li>
          <li className="mb-4">
            <strong>Enlaces a Sitios de Terceros</strong>
            <p>
              La plataforma de SpeedTech puede contener enlaces a sitios web de
              terceros. No nos hacemos responsables de las prácticas de
              privacidad o contenido de estos sitios y recomendamos a los
              usuarios revisar las políticas de privacidad de dichos sitios.
            </p>
          </li>
          <li className="mb-4">
            <strong>Cambios en la Política de Privacidad</strong>
            <p>
              SpeedTech se reserva el derecho de modificar esta política de
              privacidad en cualquier momento. Los cambios serán efectivos a
              partir de la fecha de publicación en la plataforma. Se notificará
              a los usuarios sobre cambios significativos en la política de
              privacidad.
            </p>
          </li>
          <li>
            <strong>Contacto</strong>
            <p>
              Si los usuarios tienen preguntas, inquietudes o solicitudes
              relacionadas con la privacidad, pueden ponerse en contacto con
              nosotros a través de info@speedtechcr.com
            </p>
          </li>
        </ol>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
