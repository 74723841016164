import React, { useContext } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import api from "../lib/api";
import { CartContext } from "../context/Cart";
import { toast } from "sonner";
import { CountryContext } from "../context/Country";
import { useNavigate } from "react-router-dom";

function PayPalBtn() {
  const { cart, clearCart } = useContext(CartContext);
  const { country } = useContext(CountryContext);
  const navigate = useNavigate();
  async function createOrder() {
    const { data } = await api.post("/payment/create", { cart, country });
    return data.id;
  }

  async function onApprove(data) {
    try {
      await api.post("/payment/capture", {
        orderID: data.orderID,
      });
      toast.loading("Procesando pago");

      setTimeout(() => {
        toast.success("Pago realizado con éxito");
        clearCart();
        navigate("/success");
      }, 3000);
    } catch (error) {
      toast.error("Proceso de pago fallido");
    }
  }

  function onError(err) {
    console.log(err);
    toast.error("Proceso de pago fallido");
  }

  function onCancel() {
    toast.warning("Proceso de pago cancelado");
  }

  return (
    <PayPalButtons
      onCancel={onCancel}
      onError={onError}
      createOrder={createOrder}
      onApprove={onApprove}
      style={{ layout: "vertical" }}
    />
  );
}

export default PayPalBtn;
