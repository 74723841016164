import React, { useContext, useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./views/Home";
import NotFound from "./views/NotFound";
import Products from "./views/Products";
import Product from "./views/Product";
import { CartContext } from "./context/Cart";
import { Toaster } from 'sonner';
import { CountryContext } from "./context/Country";
import Payment from "./views/Payment";
import Success from "./views/Success";
import NewDealer from "./views/NewDealer";
import Dealers from "./views/Dealers";
import TermsAndConditions from "./views/TermsAndConditions";
import PrivacyPolicy from "./views/PrivacyPolicy";
import CookiesPolicy from "./views/CookiesPolicy";
import WarrantyPolicy from "./views/WarrantyPolicy";
import Manuals from "./views/Manuals";
import Drivers from "./views/Drivers";
import ManualBill from "./views/ManualBill";


function App() {
  const { createCart } = useContext(CartContext);
  const {geolocate} = useContext(CountryContext);

  useEffect(() => {
    createCart();
    geolocate()
  }, [createCart, geolocate]);

  return (
    <>
    <Toaster  theme="dark"/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/inicio" element={<Home />} />
          <Route path="/productos" element={<Products />} />
          <Route path="/producto/:id" element={<Product />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/bill/:code" element={<ManualBill />} />
          <Route path="/success" element={<Success />} />
          <Route path="/manuales" element={<Manuals />} />
          <Route path="/drivers" element={<Drivers />} />
         <Route path="/newdealer" element={<NewDealer />} />
         <Route path="/dealers" element={<Dealers />} />
         <Route path="/condiciones-de-servicio" element={<TermsAndConditions />} />
         <Route path="/politica-de-privacidad" element={<PrivacyPolicy />} />
         <Route path="/politica-de-cookies" element={<CookiesPolicy />} />
         <Route path="/politica-de-garantia" element={<WarrantyPolicy />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
