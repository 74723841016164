import {
  faFacebook,
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="flex justify-center items-center flex-col mb-p-8">
      <div className="flex justify-center items-center gap-8 mt-8">
        <div className="mainBor flex justify-center items-center p-2 rounded-full w-[40px] h-[40px] shineEffect">
          <a
            href="https://www.tiktok.com/@speedtechcr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="mainColor cursor-pointer"
              icon={faTiktok}
            />
          </a>
        </div>

        <div className="mainBor flex justify-center items-center p-2 rounded-full w-[40px] h-[40px] shineEffect">
          <a
            href=" https://www.facebook.com/SpeedTechEFI/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="mainColor cursor-pointer"
              icon={faFacebook}
            />
          </a>
        </div>

        <div className="mainBor flex justify-center items-center p-2 rounded-full w-[40px] h-[40px] shineEffect">
          <a
            href=" https://www.youtube.com/@SpeedTechCR"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="mainColor cursor-pointer"
              icon={faYoutube}
            />
          </a>
        </div>

        <div className="mainBor flex justify-center items-center p-2 rounded-full w-[40px] h-[40px] shineEffect">
          <a
            href="https://instagram.com/speedtechcr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="mainColor cursor-pointer"
              icon={faInstagram}
            />
          </a>
        </div>
      </div>

      <section className="mt-8 flex gap-12 mb-gap-4">
        <ul className="secondFont ultraSmall diffWhite flex flex-col gap-1">
          <li>
            <Link to={"/condiciones-de-servicio"}>Términos y condiciones</Link>
          </li>
         <li>
            <Link to={"/politica-de-privacidad"}>Política de privacidad</Link>
         </li>
          <li>
            <Link to={"/politica-de-cookies"}>Política de cookies</Link>
          </li>
          <li><Link to={"/politica-de-garantia"}>Política de garantía</Link></li>
        </ul>

        <ul className="secondFont ultraSmall diffWhite flex flex-col gap-1">
          <li><Link to="/productos">Productos</Link></li>
          <li><Link to="/drivers">Drivers</Link></li>
          <li><Link to="/manuales">Manuales</Link></li>
          <li><Link to="/dealers">Dealers</Link></li>
        </ul>

        <ul className="secondFont ultraSmall diffWhite flex flex-col gap-1">
          <li><Link to={"/newdealer"}>Quiero ser dealer</Link></li>
          <li>
            SpeedTech 2023 ©
          </li>
          <li>Desarrollador</li>
        </ul>
      </section>
    </footer>
  );
}

export default Footer;
