import React, { useEffect } from "react";
import Nav from "../components/Nav";
import useProducts from "../hooks/useProducts";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
} from "@nextui-org/react";
import DownloadBtn from "../components/DownloadBtn";
import Footer from "../components/Footer";

function Manuals() {
  const [products, searchProducts] = useProducts();
  const [isMobile, setIsMobile] = React.useState(false);

  useEffect(() => {
    if (window.innerWidth < 375) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <Nav />

      <main className="flex  mt-12 min-h-screen secondFont items-center flex-col mb-p-4">
        <div className="w-1/2 mb-full">
          <div className="flex gap-8 items-center justify-end mb-flex-col">
            <Input
              placeholder="Buscar"
              onInput={(e) => searchProducts(e.target.value)}
              className="w-1/4 mb-full "
            />
          </div>
          <Table
            className="mt-5 overflow-x-hidden overflow-y-hidden"
            isStriped
            aria-label="Example static collection table"
          >
            <TableHeader>
              <TableColumn>Producto</TableColumn>
              <TableColumn>Manual</TableColumn>
              <TableColumn>Pinout</TableColumn>
            </TableHeader>
            <TableBody className="">
              {products.map((product) => {
                if (product.manual || product.pinout) {
                  return (
                    <TableRow key={product.id}>
                      <TableCell className="sm-ultraSmall">
                        {product.name}
                      </TableCell>
                      <TableCell>
                        <DownloadBtn
                          className={"sm-ultraSmall sm-p-1"}
                          file={product.manual}
                          title={!isMobile && "Manual"}
                          color="secondBg"
                        />
                      </TableCell>

                      <TableCell>
                        <DownloadBtn
                          className={"sm-ultraSmall  sm-p-1"}
                          file={product.pinout}
                          title={!isMobile && "Pinout"}
                          color="secondBg"
                        />
                      </TableCell>
                    </TableRow>
                  );
                } else {
                  return null;
                }
              })}
            </TableBody>
          </Table>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Manuals;
