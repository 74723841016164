import React, { useContext } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCartShopping,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { CartContext } from "../context/Cart";
import Price from "./Price";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { CountryContext } from "../context/Country";

export default function App() {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { itemsInCart, cart, removeFromCart, getCartTotal, getIsValidCart } =
    useContext(CartContext);
  const { country } = useContext(CountryContext);
  const navigate = useNavigate();

  return (
    <>

      <button onClick={onOpen}>
        <span>{itemsInCart()}</span>
        <FontAwesomeIcon
          className="secondColor cursor-pointer"
          icon={faCartShopping}
        />
      </button>
      <Modal
        backdrop="blur"
        size="4xl"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">Carrito</ModalHeader>
              {isOpen && (
                <ModalBody>
                  {cart.map((item, index) => (
                    <div className="flex gap-8 diffBg  rounded-2xl" key={index}>
                      <img
                        src={`${process.env.REACT_APP_API}/files/${item.images[0]}`}
                        alt=""
                        className="w-[95px] rounded h-[95px] object-cover"
                      />

                      <div className="w-full p-3">
                        <span>{item.name}</span>
                        {country !== "CR" && item.onlyCR && (
                          <span className="secondFont mainBg p-1 ml-3 text-center small ">
                             No disponible
                          </span>
                        )}
                        <br />
                        <span className="secondFont text-center small diffWhite">
                          {item.shortDescription}
                        </span>
                        <br />
                        <div className="flex w-full justify-between">
                          <Price
                            price={item.price}
                            internationalPrice={item.internationalPrice}
                            className={"mainColor secondFont"}
                            discount={item.discount}
                          />
                          <FontAwesomeIcon
                            onClick={() => {
                              removeFromCart(item);
                            }}
                            icon={faTrash}
                            className="mainColor cursor-pointer"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </ModalBody>
              )}
              <ModalFooter className="flex items-center">
                <Price className="bold white" price={getCartTotal()} />
                <Button
                  onPress={() => {
                    if (getIsValidCart() === true) {
                    if (cart.length > 0) navigate("/payment");
                    else
                      toast("Agrega productos al carrito", {
                        type: "error",
                      });
                    }   
                  }}
                  color="primary"
                >
                  Finalizar compra
                  <FontAwesomeIcon
                    className="secondColor cursor-pointer"
                    icon={faArrowRight}
                  />
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
