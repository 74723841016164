import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "sonner";
import { CountryContext } from "./Country";

const CartContext = createContext();

const CartState = (props) => {
  const { country } = useContext(CountryContext);
  const [cart, setCart] = useState([]);
  const [isValidCart, setIsValidCart] = useState(true);

  const createCart = useCallback(() => {
    const cart = localStorage.getItem("cart");
    if (cart) {
      setCart(JSON.parse(cart));
    }
  }, []);

  useEffect(() => {
    if (country !== "CR" && cart.find((item) => item.onlyCR)) {
      toast.error(
        "En tu carrito hay productos que no pueden ser enviados a tu país"
      );
      setIsValidCart(false);
    }
    setIsValidCart(true);
  }, [country, cart]);

  const addToCart = (product) => {
    setCart([...cart, product]);
    localStorage.setItem("cart", JSON.stringify([...cart, product]));
    toast.success(`${product.name} se ha añadido al carrito`);
  };

  const removeFromCart = (product) => {
    setCart(cart.filter((item) => item.id !== product.id));
    localStorage.setItem(
      "cart",
      JSON.stringify(cart.filter((item) => item.id !== product.id))
    );
    toast.success(`${product.name} se ha eliminado del carrito`);
  };

  const getCartTotal = () => {
    if (country === "CR")
      return cart.reduce(
        (sum, { price, discount }) => sum + (price - (price * discount) / 100),
        0
      );
    else {
      return cart.reduce((sum, { price, internationalPrice, discount }) => {
        const tempPrice = internationalPrice ? internationalPrice : price;
        return sum + (tempPrice - (tempPrice * discount) / 100);
      }, 0);
    }
  };

  const itemsInCart = () => {
    return cart.length;
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem("cart");
  };

  const getIsValidCart = () => {
    if (country !== "CR" && cart.find((item) => item.onlyCR)) {
      toast.error(
        "En tu carrito hay productos que no pueden ser enviados a tu país"
      );
      return false;
    }
    return true;
  };

  const getDeliveryTotal = () => {
    const centroAmerica = ["GT", "HN", "SV", "NI", "CR"];
    if (country === "CR") return 3000;
    else if (centroAmerica.includes(country)) return 16080;
    else if (country === "US") return 18000;
    else if (country === "ES") return 43390;
    else return 19890;
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        getCartTotal,
        itemsInCart,
        createCart,
        getDeliveryTotal,
        clearCart,
        isValidCart,
        getIsValidCart,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export { CartContext, CartState };
