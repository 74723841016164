import React from "react";
import Nav from "../components/Nav";
import { Input } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ProductCard from "../components/ProductCard";
import LoadingCard from "../components/LoadingCard";
import useProducts from "../hooks/useProducts";
import Footer from "../components/Footer";

function Products() {
  
  const [products, searchProducts] = useProducts();


  return (
    <>
      <Nav />

      <header className="flex gap-8  justify-end px-12 mt-12 w-full">
        <Input
          classNames={{
            base: "max-w-full sm:max-w-[10rem] h-10",
            mainWrapper: "h-full",
            input: "text-small",
            inputWrapper:
              "h-full font-normal text-default-500 bg-default-400/20 dark:bg-default-500/20",
          }}
          placeholder="Buscar productos"
          size="sm"
          onInput={(e) => searchProducts(e.target.value)}
          startContent={<FontAwesomeIcon icon={faEye} />}
          type="search"
        />

      </header>

      <main className="flex mb-mt-12 mt-8 mb-jus-center flex-wrap gap-8 px-8 min-h-screen">
        {products.length > 0 ? (
          products.map((product) => <ProductCard key={product.id} product={product} />)
        ) : (
          <>
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
            <LoadingCard />
          </>
        )}
      </main>
      <Footer />
    </>
  );
}

export default Products;
