import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";

function Success() {
  return (
    <main className="whiteBg w-screen h-screen black secondFont mb-p-8 min-h-screen">
      <div className="flex flex-col items-center justify-center h-full">
        <img className="w-[90px] mb-3 h-[90px] rounded-full" src="st.jpg" alt="" />
        <img src="" alt="" />
        <h1 className="text-4xl font-bold mb-text-center">¡Gracias por tu compra!</h1>
        <p className="text-xl mb-text-center mt-3">
          Recibirás un correo con los detalles de tu compra
        </p>

        <div className="mt-4">
            <a href="https://wa.me/+50687498077" target="_blank"  rel="noreferrer" className="flex gap-2 items-center">
            <FontAwesomeIcon icon={faWhatsapp} className="whatsApp" size="2xl" />
            (506) 8749 8077
            </a>
        </div>

        <Link className="secondBg mt-4 p-4 rounded white flex gap-2 " to="/">
            <FontAwesomeIcon icon={faArrowLeft}  />
            Volver a la tienda
        </Link>
      </div>
    </main>
  );
}

export default Success;
