import { useEffect, useState } from "react";
import api from "../lib/api";

export default function useCurrency() {
  const [value, setValue] = useState(0);

  useEffect(() => {
    const getCurrency = async () => {
      try {
        const { data } = await api.get("/currency");
      setValue(data.currency || 540);
      } catch (error) {
        setValue(540);
      }
    };
    getCurrency();
  }, []);

  return [value, setValue];
}
