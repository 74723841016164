import { useState } from "react";

const ProductCarousel = ({ product }) => {
    const [mainImage, setMainImage] = useState(product.images[1]);
    return (
    <div className="w-2/4 mb-full flex gap-5">
      <div className="w-2/4 mb-full">
        <img
          className="rounded"
          src={`${process.env.REACT_APP_CDN}/${mainImage}`}
          alt={product.name}
        />
      </div>

      <div className="flex flex-col gap-5 overflow-y-hidden overflow-y-hiden">
        {product.images.slice(1).map((image, index) => (
          <img
          onClick={() => setMainImage(image)}
            key={index}
            className="h-[50px] w-[50px] object-cover rounded cursor-pointer"
            src={`${process.env.REACT_APP_CDN}/${image}`}
            alt={product.name}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductCarousel;
