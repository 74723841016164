import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function CookiesPolicy() {
  return (
    <>
      <Nav />

      <main className="secondFont px-16 mb-p-4 mt-16 min-h-screen">
        <h1>Política de Cookies</h1>

        <p className="diffWhite">
          Esta Política de Cookies es parte integrante del Aviso Legal y la
          Política de Privacidad de SpeedTech. El acceso y la navegación en el
          sitio, o el uso de los servicios del mismo, implican la aceptación de
          las Condiciones Generales establecidas en nuestro Aviso Legal (y por
          tanto de la Política de Privacidad y Política de Cookies). Por favor,
          lee atentamente.
        </p>

        <h2>¿Qué es una Cookie?</h2>

        <p className="diffWhite">
          Las cookies son pequeños archivos de texto que se almacenan en el
          dispositivo del usuario de Internet al visitar una página web. Es el
          operador del sitio web el que determina qué tipo de información
          contiene dicha cookie y qué uso le da a la información que obtiene.
        </p>

        <p className="diffWhite">
          Las cookies son habituales y se utilizan ampliamente para hacer que
          los sitios web funcionen de manera más eficiente, así como para
          proporcionar información a los propietarios del sitio web.
        </p>

        <h2>Uso de Cookies por parte de SpeedTech</h2>

        <p className="diffWhite">
          Al acceder al sitio web, aceptas expresamente el uso de este tipo de
          cookies en tus dispositivos. Si desactivas las cookies, puede que tu
          navegación por el sitio web no sea óptima y algunas de las utilidades
          que dispone el sitio web no funcionen correctamente. En concreto,
          SpeedTech está utilizando cookies de Google Analytics para la
          obtención de datos estadísticos de tu navegación por el sitio web. Las
          cookies que utiliza Google Analytics son las siguientes: _utma, _utmb,
          _utmc y _utmz. Ademas de cookies de geo-localización de la IP del
          usuario para ofrecer contenidos y servicios adecuados a su ubicación.
          Utilizamos el almacenamiento local de HTML5 para el registro de
          productos en el carrito de compra, dustribuidores y productos en
          general, esto para mejorar la experiencia del usuario al incrementar
          la velocidad de carga de la página. Finalmente se utilizan cookies de
          PayPal para el pago de productos y servicios, estas cookies son
          necesarias para el funcionamiento de la plataforma de pagos de PayPal.
          Nuestra política de cookies está sujeta a actualizaciones periódicas.
          Si deseas contactar con nosotros respecto nuestra política de cookies,
          puedes hacerlo en
        </p>
      </main>
      <Footer />
    </>
  );
}

export default CookiesPolicy;
