import React from "react";

import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenuItem,
  NavbarMenu,
  Avatar,
} from "@nextui-org/react";
import { Link } from "react-router-dom";
import CartModal from "./CartModal";
import CountriesSelection from "./CountriesSelection";

export default function App() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const menuItems = [
    "Inicio",
    "Productos",
    "Manuales",
    "Drivers",
    "Dealers",
  ];


  return (
    <Navbar
      className="baseBg white"
      shouldHideOnScroll
      onMenuOpenChange={setIsMenuOpen}
    >
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <NavbarBrand>
          <Avatar src="/st.jpg" />
        </NavbarBrand>
      </NavbarContent>

      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <NavbarItem>
          <Link to={"/"}>Inicio</Link>
        </NavbarItem>
        <NavbarItem>
          <Link to={"/productos"}>Productos</Link>
        </NavbarItem>

        <NavbarItem>
          <Link to={"/manuales"}>Manuales</Link>
        </NavbarItem>

        <NavbarItem>
          <Link to={"/drivers"}>Drivers</Link>
        </NavbarItem>

        <NavbarItem>
          <Link to={"/dealers"}>Dealers</Link>
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem>
          <CountriesSelection />
        </NavbarItem>
        <NavbarItem>
          <CartModal />
        </NavbarItem>
      </NavbarContent>
      <NavbarMenu>
        {menuItems.map((item, index) => (
          <NavbarMenuItem key={`${item}-${index}`}>
            <Link
              color={
                index === 2
                  ? "primary"
                  : index === menuItems.length - 1
                  ? "danger"
                  : "foreground"
              }
              className="w-full"
              to={"/" + item}
              size="lg"
            >
              {item}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </Navbar>
  );
}
