import React, { useState } from "react";
import Nav from "../components/Nav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Button } from "@nextui-org/react";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";

function Home() {
  const [extendAbout, setExtendAbout] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Nav />

      <main>
        <section className="flex homeBg  h-screen justify-center items-center flex-col">
          <img src="st.webp" alt="" />
        
      
          <p className="secondFont w-[40vw] text-xl diffWhite mb-p-4 text-center mb-full">
            Con SpeedTech nunca antes fue tan fácil tener una computadora programable.
          </p>
          <span className="mainColor bold small  p-2 rounded-xl  mainFont">
            #Off Road Use Only
          </span>


          <div className="flex justify-center items-center gap-8 mt-8">
            <div className="mainBor flex justify-center items-center p-2 rounded-full w-[40px] h-[40px] shineEffect">
              <a
                href="https://www.tiktok.com/@speedtechcr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  className="mainColor cursor-pointer"
                  icon={faTiktok}
                />
              </a>
            </div>

            <div className="mainBor flex justify-center items-center p-2 rounded-full w-[40px] h-[40px] shineEffect">
              <a
                href=" https://www.facebook.com/SpeedTechEFI/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  className="mainColor cursor-pointer"
                  icon={faFacebook}
                />
              </a>
            </div>

            <div className="mainBor flex justify-center items-center p-2 rounded-full w-[40px] h-[40px] shineEffect">
              <a
                href=" https://www.youtube.com/@SpeedTechCR"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  className="mainColor cursor-pointer"
                  icon={faYoutube}
                />
              </a>
            </div>

            <div className="mainBor flex justify-center items-center p-2 rounded-full w-[40px] h-[40px] shineEffect">
              <a href="https://instagram.com/speedtechcr" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon
                  className="mainColor cursor-pointer"
                  icon={faInstagram}
                />
              </a>
            </div>
          </div>

          <Button onPress={() => {navigate("/productos")}} className="secondBg px-8 py-5 white mt-12">
            Ver tienda
            <FontAwesomeIcon icon={faArrowAltCircleRight} className="ml-2" />
          </Button>
        </section>

        <section className="mb-sec-p h-fit aboutBg py-12 parallax-content">
          <div className="flex justify-center items-center flex-col">
            <h1 className="text-4xl font-bold secondFont">Sobre Nosotros</h1>
            <p className="secondFont small mt-8 w-[40vw] diffWhite light  mb-full">
              En nuestra empresa costarricense nos dedicamos al diseño y
              producción de computadoras programables de bajo costo, pensando
              siempre en la necesidad de nuestros clientes y en la economía de
              sus bolsillos. Sabemos que tener acceso a tecnología de calidad no
              debería ser un lujo, sino un derecho de todos. Por esta razón,
              todas nuestras ECUs (unidades de control electrónico) están
              diseñadas y ensambladas con pasión y cuidado extremo, utilizando
              componentes de alta calidad para garantizar la durabilidad y el
              óptimo rendimiento de todos nuestros equipos. Si tienes alguna
              pregunta o necesitas más información sobre nuestros productos, no
              dudes en ponerte en contacto con nosotros a través de nuestro
              correo electrónico o número telefónico de atención al cliente.{" "}
              {!extendAbout && (
                <span
                  onClick={() => {
                    setExtendAbout(true);
                  }}
                  className="secondColor cursor-pointer"
                >
                  Ver más
                </span>
              )}
            </p>

            {extendAbout && (
              <>
                <p className="secondFont small mt-8 w-[40vw] diffWhite light  mb-full">
                  Nuestro equipo de ingenieros y técnicos altamente capacitados
                  están siempre dispuestos a brindar soporte y asesoría técnica
                  a nuestros clientes, garantizando así una experiencia de
                  compra satisfactoria y un uso adecuado de nuestros productos.
                  En resumen, en nuestra empresa nos esforzamos por ofrecer
                  soluciones tecnológicas de calidad, accesibles y diseñadas
                  para satisfacer las necesidades de nuestros clientes.
                </p>

                <p className="secondFont mt-8 small w-[40vw] diffWhite light  mb-full">
                  Además, nos aseguramos de mantener precios competitivos para
                  que nuestros clientes puedan acceder a soluciones tecnológicas
                  avanzadas sin tener que gastar una fortuna. Nos enorgullece
                  ofrecer una amplia gama de productos y soluciones que van
                  desde pequeñas unidades de control hasta complejos sistemas
                  integrados, siempre con la calidad y el desempeño como
                  prioridad.{" "}
                  {extendAbout && (
                    <span
                      onClick={() => {
                        setExtendAbout(false);
                      }}
                      className="secondColor cursor-pointer"
                    >
                      Ver menos
                    </span>
                  )}
                </p>
              </>
            )}
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Home;
