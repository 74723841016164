import { createContext } from "react";
import useCurrency from "../hooks/currency";

const CurrencyContext = createContext();

const CurrencyState = ({ children }) => {

    const [currency] = useCurrency()
 

    return (
        <CurrencyContext.Provider value={{ currency }}>
        {children}
        </CurrencyContext.Provider>
    );
}  


export { CurrencyContext, CurrencyState };