import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

function WarrantyPolicy() {
  return (
    <>
      <Nav />

      <main className="secondFont px-16 mb-p4 mt-16 min-h-screen">
        <h1>Política de Garantía</h1>

        <p className="diffWhite">
          En Speed Tech, nos aseguramos de que cada una de nuestras computadoras
          sea sometida a rigurosas pruebas y revisiones por nuestro equipo de
          control de calidad, utilizando los mejores componentes del mercado
          para garantizar la duración de los equipos. Es importante que
          comprendas que la garantía que ofrecemos es únicamente contra defectos
          de fábrica. La garantía es de 1 año a partir de la fecha de compra y
          cubre cualquier defecto de fábrica que se presente en el equipo dentro
          de este periodo. 

            <br /> <br />

          Speed Tech no se hace responsable de una mala
          instalación o configuración del equipo, o de daños causados por una
          manipulación inadecuada. Si deseas asegurarte de una instalación
          profesional y un manejo adecuado de tu equipo, te recomendamos
          dirigirte a uno de nuestros talleres de instalación autorizados.
          Podrás encontrar una lista de nuestros distribuidores autorizados
          siguiendo el siguiente enlace: Dist. Autorizados  En caso de que
          necesites hacer uso de nuestra garantía, por favor ponte en contacto
          con nosotros a través de nuestro correo electrónico o número
          telefónico de atención al cliente. Te proporcionaremos la asistencia
          necesaria para tramitar la garantía y asegurarnos de que tu equipo
          reciba el mantenimiento adecuado.
        </p>
      </main>
      <Footer />
    </>
  );
}

export default WarrantyPolicy;
