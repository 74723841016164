import { useState, useEffect, useContext, useRef } from "react";
import { CountryContext } from "../context/Country";
import { toast } from "sonner";
import api from "../lib/api";

const useDealers = () => {
  const [dealers, setDealers] = useState([]);
  const { country } = useContext(CountryContext);
  const originalDealers = useRef([]);

  useEffect(() => {
    if (sessionStorage.getItem("dealers")) {
      setDealers(JSON.parse(sessionStorage.getItem("dealers")));
      originalDealers.current = JSON.parse(sessionStorage.getItem("dealers"));
    } else {
      api
        .get("/dealers")
        .then((response) => {
          setDealers(response.data);
          sessionStorage.setItem("dealers", JSON.stringify(response.data));
          originalDealers.current = response.data;
        })
        .catch((error) => {
          toast.error("Error al cargar los distribuidores");
        });
    }
  }, []);

  useEffect(() => {
    const filteredDealers = originalDealers.current.filter(
      (dealer) => dealer.country === country
    );
    setDealers(filteredDealers);
  }, [dealers, country]);

  return {dealers};
};

export default useDealers;
