import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { NextUIProvider } from "@nextui-org/react";
import { CartState } from "./context/Cart";
import { CountryState } from "./context/Country";
import { CurrencyState } from "./context/Currency";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PayPalScriptProvider
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "USD",
        intent: "capture",
      }}
    >
      <NextUIProvider>
        <CountryState>
          <CurrencyState>
            <CartState>
              <App />
            </CartState>
          </CurrencyState>
        </CountryState>
      </NextUIProvider>
    </PayPalScriptProvider>
  </React.StrictMode>
);
