import React, { useContext, useEffect, useState } from "react";
import Nav from "../components/Nav";
import { Button } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import api from "../lib/api";
import ProductImages from "../components/ProductImages";
import DownloadBtn from "../components/DownloadBtn";
import { CartContext } from "../context/Cart";
import Price from "../components/Price";
import Footer from "../components/Footer";

function Product() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const { addToCart } = useContext(CartContext);

  useEffect(() => {
    const getProduct = async () => {
      const { data } = await api.get(`/products/${id}`);
      data.details = data.details ? data.details.split(",") : [];
      data.images = data.images ? JSON.parse(data.images) : [];
      setProduct(data);
    };

    getProduct();
  }, [id, setProduct]);

  return (
    <>
      <Nav />
      {product && (
        <main className="px-12 mt-12 min-h-screen">
          <div className="flex mb-flex-col gap-y-12">
            <div className="w-1/2 mb-full flex flex-col gap-5">
              <h1 className="text-5xl ">{product.name}</h1>
              <p className="small w-3/4 diffWhite secondFont">
                {product.description}
              </p>
              <span>
                <Price className={"secondColor"} price={product.price} internationalPrice={product.internationalPrice} discount={product.discount}/>
              </span>
              <Button onPress={() => addToCart(product)} color="primary" className="w-1/2 mb-full">
                Agregar al carrito <FontAwesomeIcon icon={faCartPlus} />
              </Button>
            </div>

            <ProductImages product={product} />
          </div>

          <div className="mt-16">
            <h4>Detalle del producto</h4>
            <ul className="mt-4">
              {product.details.map((detail) => (
                <li className="secondFont small diffWhite">{detail}</li>
              ))}
            </ul>
            <DownloadBtn file={product.technicalSheet} title="Ficha técnica" />
          </div>
        </main>
      )}
      <Footer />
    </>
  );
}

export default Product;
